import aboutImage from "./../../assets/jpgs/AboutImage.jpg"
import firstPartner from "./../../assets/jpgs/gorcnker.jpg"
import secondPartner from "./../../assets/jpgs/gorcnker2.jpg"
export default [
  {
    title: "ՄԵՐ ՄԱՍԻՆ",
    text: `Մեր մասին
    Մասնագիտանալով  միջին և բարձր դասի լուսամուտների արտադրության ոլորտում՝ կարևորում ենք սպասարկման բարձր չափանիշները։
    Մենք իրականացնում ենք խորհրդատվություն, կատարում մետաղապլաստե, ալյումինե և ապակե ցանկացած բարդության դռների և լուսամուտների նախագծում, արտադրություն և տեղադրում։ 
    «Լուսամուտ»-ի  ինժեներներն ունեն առնվազն 10 տարվա աշխատանքային փորձ և համապատասխան որակավորում, ինչի շնորհիվ ցուցաբերում են մասնագիտական ցանկացած օգնություն և գոհացնում անգամ ամենաքմահաճ հաճախորդին։ Կատարում ենք երաշխիքային և հետերաշխիքային սպասարկում, ընձեռում հնարավորություն ՝ օգտվելու աշխարհահռչակ բրենդների արտադրանքներից։ 
    Մեր հաճախորդների վստահությունը՝ մեր հաջողության գրավականն է։
    
    Մեր առաքելույթունը
    1․ Ստեղծել գեղեցիկ և հարմարավետ միջավայր՝ ի օգուտ մարդկանց։
    2․Ստեղծել որակի այնպիսի չափորոշիչներ, որոնք կօգնեն ամրապնդել սպառողի և արտադրողի փոխադարձ վստահությունը։
    
    Տեսլական
    Լինել մեր հաճախորդների ընկերը ՝ պաշտպանելով նրանց շահերը արտադրական և սպասարկման բոլոր փուլերում։
    
    Արժեքներ և սկզբունքներ
    
    - Մտածում ենք ի շահ հաճախորդի։
    - Կարևորում ենք ճշտապահությունը և արտադրանքի բարձր որակը։
    - Գործում ենք համախմբված։
    - Համատեղում ենք էկոլոգիապես մաքուրն ու հարմարավետը։ 
     `,
    image: aboutImage
  },
  {
    title: "ՄԵՐ ԳՈՐԾԸՆԿԵՐՆԵՐԸ",
    text: "Լուսամուտների մեխանիզմներ արտադրող գործարանների շարքում MACO ավստրիական ընկերությունը անփոխարինելի բարձր որակ ունի թե՛ ֆունկցիոնալության, թե՛ ծածկույթի վերաբերյալ, որն անընդհատ հաստատվում է վերահսկողական թեստերով: Հակակողոպտիչ ֆունկցիոնալությունը կարևորագույն պահանջներից է, երբ հարցը վերաբերում է դռներին և լուսամուտներին: Դա հիմնականում մեխանիկական հակազդեցության արդյունք է: MACO-ն դրա էքսպերտն է: MACO-ն ունի բացառիկ լուծումներ՝ հենված EVO-էֆֆեկտի վրա: Ֆուրնիտուրան օգտագործվում է աշխարհի տարբեր կլիմայական պայմաններում և պարտավոր է պաշտպանել և չվնասվել: ",
    text2: "MACO-ի մասնաճյուղերը գործում են աշխարհի ավելի քան 20 երկրներում։ Սակայն բոլոր մեխանիզմները MACO-ն արտադրում է բացառապես Ավստրիայում՝  պահպանելով որակի բարձրագույն ստանդարտները: Զալցբուրգի MACO-ի տարածքում գործում է հզոր գիտահետազոտական կենտրոն, որը դռների և լուսամուտների մեխանիզմները հասցնում է ժամանակակից գիտական տեխնոլոգիաների մակարդակին:",
    image: firstPartner
  },
  {
    title: "ՄԵՐ ԳՈՐԾԸՆԿԵՐՆԵՐԸ",
    text: "Բելգիական Deceuninck ընկերությունը լուսամուտների և դռների պրոֆիլներ արտադրող աշխարհի լավագույն ընկերություններից է։ Տարբեր երկրներում Deceuninck ընկերությունում  աշխատում են ավելի քան 3600 մասնագետներ։ Deceuninck-ը սպասարկում է հաճախորդներին 91 երկրներում, ունի 17 գործարան աշխարհում։",
    text2: "76 մմ հաստությամբ FAVORIT SPACE  A+ դասի մետաղապլաստե պրոֆիլն  ունի 6 ջերմամեկուսացման խցիկ, որի մեջտեղում տեղադրված է լրացուցիչ ռետինե շերտ՝  լրացնելով դրսի և ներսի մեկուսիչները։ Իսկ ահա 60 մմ հաստությամբ ECO 60 պրոֆիլն ունի 4 ջերմամեկուսացման խցիկ։ Երկու պրոֆիլներն ունեն եռաշերտ ապակեփաթեթների հնարավորություն, էկոլոգիապես մաքուր և անվտանգ են առողջության համար։ Դիմանում են −60-ից +80°с ջերմաստիճաններին և կատարում են մինչեւ 40 ԴԲ ձայնամեկուսացում։Ավելի մանրամասն կարող եք ծանոթանալ՝ այցելելով deceuninck.ru։",
    image: secondPartner
  }
]