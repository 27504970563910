import sliderImage2 from "../../assets/jpgs/sliderImage2.jpg"
import sliderImage3 from "../../assets/jpgs/sliderImage3.jpg"
import sliderImage4 from "../../assets/jpgs/sliderImage4.jpg"
import sliderImage5 from "../../assets/jpgs/sliderImage5.jpg"
import sliderImage6 from "../../assets/jpgs/sliderImage6.jpg"
import sliderImage7 from "../../assets/jpgs/sliderImage8.jpg"
import sliderImage9 from "../../assets/jpgs/sliderImage10.jpg"
import sliderImage10 from "../../assets/jpgs/sliderImage12.jpg"
import sliderImage11 from "../../assets/jpgs/sliderImage13.jpg"
import sliderImage12 from "../../assets/jpgs/sliderImage15.jpg"
import sliderImage13 from "../../assets/jpgs/sliderImage16.jpg"
import sliderImage14 from "../../assets/jpgs/sliderImage7.jpg"
export default [
    {
        image: sliderImage3,
        title: "ՄԵՏԱՂԱՊԼԱՍՏԵ ԵՎ ԱԼՅՈՒՄԻՆԵ ԴՌՆԵՐԻ և ՊԱՏՈՒՀԱՆՆԵՐԻ ԼԱՅՆ ՏԵՍԱԿԱՆԻ",
    },
    {
        image: sliderImage4,
    },
    {
        image: sliderImage2,
    },
    {
        image: sliderImage6,
    },
    {
        image: sliderImage14,   
    },
    {
        image: sliderImage9,
    },
    {
        image: sliderImage10,
    },
    {
        image: sliderImage13,
    },
    {
        image: sliderImage5,
    },
    {
        image: sliderImage11,
    },
    {
        image: sliderImage7,
    },
    {
        image: sliderImage12,
    }       
]